import { toCamelCase, toPascalCase } from '~/helpers/stringHelper.js'

export const formatEquipmentTags = ({
  $i18n,
  brands,
  equipments,
  equipmentTypes,
  formatCofeeMaker = true,
  formatWithoutPlurial = false,
}) => {
  const equipmentTags = []

  for (const equipment of equipments) {
    const coffeMakerIdentifiers = [
      'filter_coffee_machine',
      'coffee_bean_machine',
      'pod_coffee_machine',
    ]
    const bedIdentifiers = [
      'single_bed',
      'two_single_beds',
      'inseparable_double_bed',
    ]
    const brandedIdentifiers = [...coffeMakerIdentifiers, 'sound_system']
    const equipmentType = equipmentTypes.find(
      (x) => x.id === equipment.equipmentTypeId,
    )
    const coffeeMaker = coffeMakerIdentifiers.includes(equipmentType.identifier)
    const equipementTagExisting = equipmentTags.find((e) => {
      if (coffeeMaker && formatCofeeMaker) {
        return e.identifier === 'coffee_maker'
      }

      return e.identifier === equipmentType.identifier
    })

    if (equipementTagExisting && equipmentType.identifier !== 'pool') {
      equipementTagExisting.quantity =
        equipementTagExisting.quantity + equipment.quantity
      equipementTagExisting.name = nameFormatted({
        $i18n,
        equipmentType,
        formatWithoutPlurial,
        quantity: equipementTagExisting.quantity,
      })
    } else {
      const equipmentAttributes = {
        essential: equipmentType.essential,
        id: equipment.id,
        identifier: equipmentType.identifier,
        name: nameFormatted({
          $i18n,
          equipmentType,
          formatWithoutPlurial,
          quantity: equipment.quantity,
        }),
        quantity: equipment.quantity,
      }

      if (equipmentType.identifier === 'table') {
        Object.assign(equipmentAttributes, {
          capacity: equipment.capacity,
          trad: 'house.rooms.table',
        })
      }

      if (equipmentType.identifier === 'pool') {
        Object.assign(equipmentAttributes, {
          poolId: equipment.poolId,
        })
      }

      if (coffeeMaker && formatCofeeMaker) {
        Object.assign(equipmentAttributes, {
          trad: 'house.rooms.coffee_maker',
        })

        equipmentAttributes.identifier = 'coffee_maker'
      }

      if (brandedIdentifiers.includes(equipmentType.identifier)) {
        const brand = brands?.find((b) => b.id === equipment.brandId)

        Object.assign(equipmentAttributes, {
          brand: brand && brand.name !== 'Not Specified' && brand.name,
        })
      }

      if (bedIdentifiers.includes(equipmentType.identifier)) {
        Object.assign(equipmentAttributes, {
          bedSize:
            equipment.bedSize &&
            equipment.bedSize !== 'not_specified' &&
            `house.rooms.bedSizes.${toCamelCase(equipment.bedSize)}`,
          bedType:
            equipment.bedType &&
            `house.rooms.bedTypes.${toCamelCase(equipment.bedType)}`,
        })
      }

      equipmentTags.push(equipmentAttributes)
    }
  }

  return equipmentTags
}

export const formatKeyfeatureRoomTags = ({ areas, $i18n }) => {
  const areaIdentifiers = [
    'badminton_court',
    'basketball_court',
    'billiard_room',
    'conference_room',
    'fitness_room',
    'football_field',
    'game_room',
    'garden',
    'meditation_room',
    'movie_room',
    'paddle_court',
    'petanque',
    'pool_house',
    'ski_room',
    'spa',
    'tennis_court',
    'volleyball_court',
  ]

  return areas
    .filter((area) => areaIdentifiers.includes(area.identifier))
    .map((area) => {
      const name =
        area.identifier === 'garden'
          ? namedGarden(area.gardenType, $i18n)
          : area.name[$i18n.locale.value]

      return {
        gardenType: area.gardenType,
        identifier: area.identifier,
        name,
      }
    })
}

export const formatRoomOutsideTags = ({
  $i18n,
  areas,
  excludeIdentifiers = ['pool_area', 'pool_house'],
}) => {
  return areas
    .filter(
      (area) => !area.inside && !excludeIdentifiers.includes(area.identifier),
    )
    .map((area) => {
      const name =
        area.identifier === 'garden'
          ? namedGarden(area.gardenType, $i18n)
          : area.name[$i18n.locale.value]

      return {
        gardenType: area.gardenType,
        id: area.id,
        identifier: area.identifier,
        name,
        position: area.position,
      }
    })
}

export const formatRoomTags = ({
  $i18n,
  areas,
  brands,
  equipments,
  equipmentTypes,
  formatAirConditioning = false,
  withoutKitchenType = false,
}) => {
  return areas
    .filter(
      (area) => !['other_equipments', 'fitness_room'].includes(area.identifier),
    )
    .map((area) => {
      const tags = []

      if (area?.kitchenType?.length && !withoutKitchenType) {
        for (const kitchenType of area.kitchenType) {
          tags.push({ name: namedKitchen(kitchenType, $i18n) })
        }
      }

      tags.push(
        ...formatEquipmentTags({
          $i18n,
          brands,
          equipments: equipments.filter((x) => x.areaId === area.id),
          equipmentTypes,
          formatCofeeMaker: false,
          formatWithoutPlurial: false,
        }),
      )
      const airConditioningIndentifiers = [
        'air_conditioning',
        'reversible_air_conditioning',
      ]

      return {
        areaGroupIndex: area.areaGroupIndex,
        bathType: area.bathType,
        climatisation: tags.find((tag) =>
          airConditioningIndentifiers.includes(tag.identifier),
        ),
        equipments: formatAirConditioning
          ? tags.filter(
              (tag) => !airConditioningIndentifiers.includes(tag.identifier),
            )
          : tags,
        gardenType: area.gardenType,
        id: area.id,
        kitchenType: area.kitchenType,
        name: area.name[$i18n.locale.value],
        position: area.position,
        view:
          area.view && area.view !== 'no_view' && area.viewType
            ? `${area.view}${toPascalCase(area.viewType)}`
            : null,
      }
    })
}

export const formatSurroundingEnvironmentTags = (surroundingInformations) => {
  const environmentTags = []

  if (surroundingInformations.locatedInCity) {
    environmentTags.push({
      trad: 'house.environments.inTown',
    })
  } else if (surroundingInformations.locatedInCity !== null) {
    environmentTags.push({
      trad: 'house.environments.outOfTown',
    })
  }

  if (
    !surroundingInformations.neighborhood &&
    surroundingInformations.neighborhood !== null
  ) {
    environmentTags.push({
      trad: 'house.environments.noNeighbors',
    })
  }

  if (surroundingInformations.natureReserve) {
    environmentTags.push({
      trad: 'house.environments.natureReserve',
    })
  }

  if (surroundingInformations.surroundedByFields) {
    environmentTags.push({
      trad: 'house.environments.surroundedByFields',
    })
  }

  if (surroundingInformations.privateBeach) {
    environmentTags.push({
      trad: 'house.environments.privateBeach',
    })
  }

  if (surroundingInformations.privateDomain) {
    environmentTags.push({
      trad: 'house.environments.privateDomain',
    })
  }

  return environmentTags
}

export const formatSurroundingNearTags = (
  surroundingInformations,
  accessIdentifiers = [
    'feet_in_the_water',
    'on_car',
    'on_foot',
    'ski_in',
    'ski_out',
    'ski_in_ski_out',
  ],
  tagsWithoutAccess = true,
) => {
  const nearIdentifiers = [
    'beach',
    'downtown',
    'golf',
    'restaurantsAndBars',
    'shops',
    'skiTrail',
  ]
  const nearTags = []
  const filteredTags = []

  for (const identifier of nearIdentifiers.filter(
    (x) => surroundingInformations[x],
  )) {
    const access = surroundingInformations[`${identifier}Accessibility`]
    const filteredAccess = ['ski_in', 'ski_out', 'ski_in_ski_out']
    const time =
      surroundingInformations[`${identifier}AccessibilityTimeInMinutes`]
    const formattedTime = `${time} minutes`

    if (access && accessIdentifiers.includes(access)) {
      if (filteredAccess.includes(access)) {
        filteredTags.push({
          trad: `house.nears.${access}`,
          time: time && time <= 20 && formattedTime,
        })
      } else {
        nearTags.push({
          trad: `house.nears.${identifier}`,
          access: `house.nears.${access}`,
          time: time && time <= 20 && formattedTime,
        })
      }
    } else if (tagsWithoutAccess) {
      nearTags.push({
        trad: `house.nears.${identifier}`,
        time: time && time <= 20 && formattedTime,
      })
    }
  }

  return { nearTags, filteredTags }
}

export const formatViewTags = (surroundingInformations) => {
  const viewIdentifiers = [
    'city',
    'lake',
    'mountain',
    'nature',
    'sea',
    'village',
  ]
  const viewTags = []

  for (const identifier of viewIdentifiers) {
    if (surroundingInformations[`${identifier}View`]) {
      const viewType = surroundingInformations[`${identifier}ViewType`]

      if (viewType) {
        viewTags.push({
          trad: `house.views.${identifier}${toPascalCase(viewType)}`,
        })
      } else {
        viewTags.push({
          trad: `house.views.${identifier}`,
        })
      }
    }
  }

  return viewTags
}

export const formatWhiteBrandSurroundingTags = (surroundingInformations) => {
  const surroundingNearTags = formatSurroundingNearTags(surroundingInformations)

  return [
    ...formatViewTags(surroundingInformations),
    ...formatSurroundingEnvironmentTags(surroundingInformations),
    ...surroundingNearTags.nearTags,
    ...surroundingNearTags.filteredTags,
  ]
}

export const formatPoolTooltip = (pool, $i18n) => {
  const infos = []

  if (pool?.poolType) {
    infos.push($i18n.t(`house.pools.${pool.poolType}Pool`))
  }

  if (pool?.treatment && pool.treatment !== 'not_specified') {
    infos.push($i18n.t(`house.pools.${toCamelCase(pool.treatment)}`))
  }

  if (pool?.options?.length) {
    pool.options.forEach((option) => {
      infos.push($i18n.t(`house.pools.${toCamelCase(option)}`))
    })
  }

  if (pool?.heater && pool.heater !== 'unheated') {
    infos.push($i18n.t(`house.pools.${pool.heater}`))
  }

  const attributesSize = ['length', 'width', 'surface', 'depthMax']

  attributesSize.forEach((attribute) => {
    if (pool?.[attribute] > 0) {
      infos.push(
        $i18n.t(`house.pools.${attribute}`, {
          number: pool[attribute],
        }),
      )
    }
  })

  return infos.join(', ')
}

export const formatGardenTooltip = (gardenType, $i18n) => {
  const infos = []

  for (const graden of gardenType) {
    infos.push($i18n.t(`house.gardenTypes.${toCamelCase(graden)}`))
  }

  return infos.join(', ')
}

export const equipmentName = (equipment, $i18n, withoutFormatting = false) => {
  if (withoutFormatting) {
    return equipment.name
  }

  if (equipment.trad) {
    const equipmentTrad = $i18n.t(equipment.trad, equipment.quantity, {
      quantity: equipment.quantity,
    })

    if (equipment.capacity) {
      return (
        `${equipmentTrad} ` +
        $i18n.t(
          'house.rooms.equipment_capacity2',
          {
            capacity: equipment.capacity,
          },
          equipment.capacity,
        )
      )
    }

    return equipmentTrad
  }

  return equipment.name
}

export const namedGarden = (gardenType, $i18n) => {
  if (gardenType?.length === 1) {
    return $i18n.t('house.gardenType', {
      gardenType: $i18n.t(`house.gardenTypes.${toCamelCase(gardenType[0])}`),
    })
  }

  return $i18n.t('house.garden')
}

export const namedKitchen = (kitchenType, i18n) => {
  return i18n.t(`house.kitchenTypes.${toCamelCase(kitchenType)}`)
}

export const nameFormatted = ({
  $i18n,
  quantity,
  equipmentType,
  formatWithoutPlurial,
}) => {
  if (formatWithoutPlurial) {
    return equipmentType.name[$i18n.locale.value]
  }

  return quantity > 1
    ? `${quantity} ${equipmentType.nameInPlural[
        $i18n.locale.value
      ]?.toLowerCase()}`
    : equipmentType.name[$i18n.locale.value]
}
